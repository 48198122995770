import { createMuiTheme } from "@material-ui/core";
import { css } from "@emotion/core";

export const themeColors = {
  cyprus: "#0d2d3d",
  sherpaBlue: "#004a5f",
  cerulean: "#007498",
  hippieBlue: "#498ea2",
  allports: "rgba(255, 255, 255, 0.1)",
  casablanca: "#eeb354",
  silver: "#c1c1c1",
  concrete: "#dbdbd7",
  gainsboro: "#eae7e7",
  blackSqueeze: "#f2f2f0",
  neonCarrot: "#fd923d",
  tahitiGold: "#e07d22",
  papayaWhip: "#ffe9d8",
  white: "#ffffff",
  successGreen: "#027915",
  errorRed: "#d80000",
};

export const themeGradient = (angle) => `transparent
linear-gradient(
  ${angle}deg,
  ${themeColors.cerulean} 0%,
  ${themeColors.sherpaBlue} 100%
)
0% 0% no-repeat padding-box;`;

export const loginFormTheme = createMuiTheme({
  typography: {
    fontFamily: ["Montserrat", "Helvetica", "sans-serif"].join(","),
    h1: {
      display: "none",
    },
    body1: {
      fontSize: "13px",
    },
  },
  palette: {
    primary: {
      main: themeColors.white,
    },
    text: {
      primary: themeColors.white,
    },
    background: {
      default: themeGradient(122),
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          background: themeGradient(122),
        },
      },
    },
    MuiTextField: {
      root: {
        width: "100%",
        marginBottom: "24px",
      },
    },
    MuiFormLabel: {
      root: {
        color: themeColors.white,
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: themeColors.hippieBlue,
      },
      input: {
        padding: "14px",
        "&:-webkit-autofill": {
          "-webkit-transition-delay": "99999s",
        },
        "&:-webkit-autofill:hover": {
          "-webkit-transition-delay": "99999s",
        },
        "&:-webkit-autofill:focus": {
          "-webkit-transition-delay": "99999s",
        },
        "&:-webkit-autofill: active": {
          "-webkit-transition-delay": "99999s",
        },
      },
    },
    MuiButton: {
      outlined: {
        borderColor: themeColors.casablanca,
        borderRadius: "23px",
        textTransform: "none",
        padding: "12px 24px",
        fontWeight: "bold",
        lineHeight: "1.1",
        "&:hover:enabled": {
          backgroundColor: themeColors.casablanca,
          color: "#0f394e",
        },
        "&:disabled": {
          borderColor: themeColors.casablanca,
          color: "#0f394e",
          opacity: "0.5",
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 15px) scale(1)",
      },
    },
    MuiLink: {
      root: {
        display: "block",
        marginTop: "32px",
      },
    },
    MuiCheckbox: {
      root: {
        color: themeColors.hippieBlue,
      },
    },
  },
});

export const withFormStyles = () => css`
  width: 100vw;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  form {
    width: 372px;
    margin-top: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .form-control {
      margin-bottom: 16px;
    }

    .info {
      width: 100%;
      margin-top: 30px;
      border-radius: 16px;
      background: ${themeColors.allports};
      color: ${themeColors.white};
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 36px 80px;
      font-size: 13px;
      letter-spacing: 0;
      text-align: center;
      line-height: 1.53;
      span.emphasize {
        font-weight: 700;
        margin-bottom: 6px;
      }
    }
  }
`;
