import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";

import { useRedirect } from "react-admin";
import styled from "@emotion/styled";
import {
  Button,
  TextField,
  Typography,
  ThemeProvider,
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { loginFormTheme, withFormStyles } from "./styles";
import AuthProvider from "../../AuthProvider";
import Wordmark from "./Wordmark";

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const emailErrorMessage = "Please enter a valid email address";

const Container = styled.div`
  ${withFormStyles()}
`;
const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const redirect = useRedirect();
  const submit = async () => {
    try {
      const response = await AuthProvider.login({ username, password });
      if (response?.error) throw response;
      redirect("/");
    } catch (error) {
      setPasswordError(error?.error);
    }
    return false;
  };

  const handleEmailFieldBlur = (e) => {
    if (e.target.value === "") {
      setEmailError("");
    } else if (!emailRegex.test(e.target.value)) {
      setEmailError(emailErrorMessage);
    } else {
      setEmailError("");
    }
  };
  return (
    <ThemeProvider theme={loginFormTheme}>
      <CssBaseline>
        <Container>
          <Typography variant="h1">Run AMZ</Typography>
          <Wordmark />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              submit(e);
            }}
          >
            <TextField
              inputProps={{ "aria-label": "Email" }}
              label="Email"
              onChange={(e) => setUsername(e.target.value)}
              required
              onBlur={handleEmailFieldBlur}
              value={username}
              variant="outlined"
              error={emailError}
              helperText={emailError}
            />
            <TextField
              inputProps={{ "aria-label": "Password" }}
              label="Password"
              onChange={(e) => setPassword(e.target.value)}
              required
              type="password"
              value={password}
              variant="outlined"
              error={passwordError}
              helperText={passwordError}
            />
            <Button
              type="submit"
              disableRipple
              variant="outlined"
            >
              Login
            </Button>
            <Link component={RouterLink} to="/reset_password" underline="none">
              Forgot Password
            </Link>
            <Typography variant="body1" className="info">
              <a href="https://runamz.com/contact/" style={{ color: 'inherit', textDecoration: 'none' }}><span className="emphasize">Don&apos;t have an account?</span></a>
              <span>Reach out to your client success manager at Run AMZ</span>
            </Typography>
          </form>
        </Container>
      </CssBaseline>
    </ThemeProvider>
  );
};

export default Login;
