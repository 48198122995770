import React from 'react';
import { themeColors } from "./styles";

const Wordmark = ({ width = 106 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 299.29 79.28"
    width={`${width}px`}
  >
    <g className="a" style={{ isolation: 'isolate' }}>
      <text
        className="b"
        style={{
          isolation: 'isolate',
          fontSize: '36px',
          fontFamily: 'Montserrat-ExtraLight, Montserrat',
          fontWeight: 200,
          letterSpacing: '0.29em',
          fill: themeColors.white,
        }}
        transform="translate(0 30.6)"
      >
        S
        <tspan className="c" x="32.33" y="0">
          C
        </tspan>
        <tspan className="d" x="68.04" y="0">
          O
        </tspan>
        <tspan className="c" x="108.75" y="0">
          U
        </tspan>
        <tspan x="147.35" y="0">
          T
        </tspan>
      </text>
    </g>
    <g className="a" style={{ isolation: 'isolate' }}>
      <g className="a" style={{ isolation: 'isolate' }}>
        <path
          className="e"
          style={{ fill: themeColors.white }}
          d="M14.49,63.4a6.81,6.81,0,0,1,2.62,2.65,7.94,7.94,0,0,1,.94,3.89,7.85,7.85,0,0,1-.94,3.88,7,7,0,0,1-2.62,2.66,7.35,7.35,0,0,1-3.73,1,6.89,6.89,0,0,1-3.58-1,6.36,6.36,0,0,1-2.47-2.64v3.47H3.35v-21H4.76v9.59a6.39,6.39,0,0,1,2.46-2.56,6.87,6.87,0,0,1,3.54-.92A7.34,7.34,0,0,1,14.49,63.4Zm-.76,12a5.7,5.7,0,0,0,2.13-2.2,7.06,7.06,0,0,0,0-6.45,5.73,5.73,0,0,0-2.13-2.21,6.06,6.06,0,0,0-3.06-.79,6,6,0,0,0-3,.79,5.78,5.78,0,0,0-2.12,2.21,7.06,7.06,0,0,0,0,6.45,5.75,5.75,0,0,0,2.12,2.2,5.87,5.87,0,0,0,3,.8A6,6,0,0,0,13.73,75.37Z"
          transform="translate(-0.35 -3.65)"
        />
        <path
          className="e"
          style={{ fill: themeColors.white }}
          d="M35.53,62.56,28,79.33a6.58,6.58,0,0,1-2,2.77,4.35,4.35,0,0,1-2.65.82,5.26,5.26,0,0,1-1.91-.34,3.9,3.9,0,0,1-1.51-1l.71-1.07a3.7,3.7,0,0,0,2.74,1.16,2.94,2.94,0,0,0,1.84-.59,5.33,5.33,0,0,0,1.41-2l.8-1.78L20.77,62.56h1.5l5.91,13.21,5.91-13.21Z"
          transform="translate(-0.35 -3.65)"
        />
      </g>
    </g>
    <path
      className="e"
      style={{ fill: themeColors.white }}
      d="M60.54,64.45H53.37V77.36H51.94V47.47H62.25c4.84,0,8.82,3,8.82,8.47s-4,8.51-8.82,8.51l9.23,12.91H69.64Zm1.71-15.64H53.37v14.3h8.88a6.9,6.9,0,0,0,7.26-7.17A6.86,6.86,0,0,0,62.25,48.81Z"
      transform="translate(-0.35 -3.65)"
    />
    <path
      className="e"
      style={{ fill: themeColors.white }}
      d="M91.33,47.47h1.44V66c0,6.63,3.4,10.53,9.68,10.53s9.68-3.9,9.68-10.53V47.47h1.43V66c0,7.4-3.85,11.88-11.11,11.88S91.33,73.37,91.33,66Z"
      transform="translate(-0.35 -3.65)"
    />
    <path
      className="e"
      style={{ fill: themeColors.white }}
      d="M136.33,49.8V77.36H134.9V47.47h1.43l20.16,27.29V47.47h1.44V77.36h-1.44Z"
      transform="translate(-0.35 -3.65)"
    />
    <path
      className="f"
      style={{ fill: themeColors.neonCarrot }}
      d="M197,73.5H185.49l-1.16,3.86h-9.82l11-29.89h11.38l11,29.89h-9.81ZM187.82,66h6.81l-3.4-10.53Z"
      transform="translate(-0.35 -3.65)"
    />
    <path
      className="f"
      style={{ fill: themeColors.neonCarrot }}
      d="M249.44,58.49l-6.68,18.87h-4l-6.68-18.87V77.36H223V47.47h12.51l5.24,15.24L246,47.47h12.5V77.36h-9.05Z"
      transform="translate(-0.35 -3.65)"
    />
    <path
      className="f"
      style={{ fill: themeColors.neonCarrot }}
      d="M276,70.19l11.38-14.83H276V47.47h23.34v7.12L287.9,69.47h11.74v7.89H276Z"
      transform="translate(-0.35 -3.65)"
    />
  </svg>
);

export default Wordmark;
